import '../style/main.css'
import {useRef} from "react";

import MainLandContainer from "../elements/MainLandContent";
import MainAdvantagesContent from "../elements/MainAdvantagesContent";
import MainAboutContent from "../elements/MainAboutContent";
import MainCardsContent from "../elements/MainCardsContent";
import MainFooter from "../elements/MainFooter";
import MainNavBar from "../elements/MainNavBar";
import MainHowToContent from "../elements/MainHowToContent";
import MainReviewsContent from "../elements/MainReviewsContent";

function MainPage() {
    const advantagesPointer = useRef(null)
    const cardsPointer = useRef(null)
    const howToPointer = useRef(null)
    const reviewsPointer = useRef(null)
    const aboutPointer = useRef(null)

    const executeScroll = to => {
        switch (to) {
            case 0:
                advantagesPointer.current.scrollIntoView({behavior: "smooth", inline: "center", block: "center"})
                break
            case 1:
                aboutPointer.current.scrollIntoView({behavior: "smooth", inline: "center", block: "center"})
                break
            case 2:
                cardsPointer.current.scrollIntoView({behavior: "smooth", inline: "center", block: "start"})
                break
            case 3:
                howToPointer.current.scrollIntoView({behavior: "smooth", inline: "center", block: "center"})
                break
            case 4:
                reviewsPointer.current.scrollIntoView({behavior: "smooth", inline: "center", block: "center"})
                break
            default:
                console.log("no such switch options")
        }
    }

    return (<div className="background">
        <MainNavBar navigateTo={executeScroll}/>
        <MainLandContainer/>
        <MainAdvantagesContent reference={advantagesPointer}/>
        <MainAboutContent reference={aboutPointer}/>
        <MainCardsContent reference={cardsPointer}/>
        <MainHowToContent reference={howToPointer}/>
        <MainReviewsContent reference={reviewsPointer}/>
        <MainFooter/>
    </div>)
}

export default MainPage