import mainContainerPicture from "../res/main_container_picture.png";
import {Button} from "react-bootstrap";


function MainLandContent() {
    return (<div className="basic_container_wrapper land_container_height">
        <div className="basic_container inner_padding_top_1">
            <div className="basic_container_text land_container_text land_container_text_flex_up">
                <h1 className="header1">
                    Вернитесь к прежней жизни с иностранной картой
                </h1>
            </div>
            <div className="land_contained_picture_middle">
                <img src={mainContainerPicture} alt="land_image_01"/>
            </div>
            <div className="basic_container_text land_container_text land_container_flex_down">
                <div>
                    <h2 className="header2">Зарубежные карты Visa и MasterCard удаленно</h2>
                    <p className="land_container_text_normal">Наша команда поможет вам удаленно оформить международную карту в евро и долларах для операций
                        в валюте</p>
                    <p className="land_container_text_spec">От вас потребуется минимум документов!</p>
                    <ul>
                        <li>Оформление карты без доверенности</li>
                        <li>Гарантируем конфиденциальность</li>
                        <li>Доставка карты до двери</li>
                    </ul>
                    <Button className="land_container_button" href="https://t.me/+-pjDSNmx4rA1YmQy" target="_blank" rel="noopener noreferrer" variant="outline-info" size="lg">Получить консультацию или заказать карту</Button>
                </div>
            </div>
        </div>
    </div>)
}

export default MainLandContent