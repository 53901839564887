import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import mainLogo from "../res/main_logo.png";
import NavbarCollapse from "react-bootstrap/NavbarCollapse";
import {Nav} from "react-bootstrap";
import tgIcon from "../res/tg_icon.png";

const glassHeaderStyle = {
    background: "rgba(18, 16, 34, 0.7)",
    boxShadow: "0 4px 30px rgba(0, 0, 0, 0.1)",
    backdropFilter: "blur(5px)",
    fontFamily: "SF Pro Display"
}

function MainNavBar(payload) {
    return (
        <Navbar collapseOnSelect sticky="top" variant="dark" expand="lg" style={glassHeaderStyle}>
            <Container>
                <Navbar.Brand href="/">
                    <img className="main_logo" src={mainLogo} alt={"main_logo"}/>
                    <Navbar.Text href="/" style={{textDecoration: "none", color: "white"}}>Global Card</Navbar.Text>
                </Navbar.Brand>
                <NavbarCollapse>
                    <Nav>
                        <Nav.Link onClick={() => payload.navigateTo(0)}>Преимущества</Nav.Link>
                        <Nav.Link onClick={() => payload.navigateTo(1)}>О нас</Nav.Link>
                        <Nav.Link onClick={() => payload.navigateTo(2)}>Карты</Nav.Link>
                        <Nav.Link onClick={() => payload.navigateTo(3)}>Как получить</Nav.Link>
                        <Nav.Link onClick={() => payload.navigateTo(4)}>Отзывы</Nav.Link>
                    </Nav>
                </NavbarCollapse>
                <Nav className="ms-auto">
                    {/* for future elements plus it's really pushes other text to right xd */}
                </Nav>
                <a href="https://t.me/+-pjDSNmx4rA1YmQy" target="_blank" rel="noopener noreferrer"><img className="tg_style_icon" src={tgIcon} alt={"tg_icon"}></img></a>
                <Navbar.Toggle/>
            </Container>
        </Navbar>
    )
}
export default MainNavBar