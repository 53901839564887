import {Carousel} from "react-bootstrap";
import review01 from "../res/review1.png";
//import review02 from "../res/review2.png";
import review03 from "../res/review3.png";
import review04 from "../res/review4.png";

function MainReviewsContent(payload) {
    return (
        <div className="basic_container_wrapper second_background">
            <div className="full_height_container_text basic_container_vertical" ref={payload.reference}>
                <div className="reviews_container">
                    <h3 className="header2">Отзывы наших клиентов</h3>
                    <div className="reviews_carousel_wrapper">
                        <Carousel>
                            <Carousel.Item>
                                <div className="reviews_carousel_image_wrapper">
                                    <img className="reviews_carousel_image" src={review01} alt="review_image_01"/>
                                </div>
                            </Carousel.Item>
                            <Carousel.Item>
                                <div className="reviews_carousel_image_wrapper">
                                    <img className="reviews_carousel_image" src={review04} alt="review_image_02"/>
                                </div>
                            </Carousel.Item>
                            <Carousel.Item>
                                <div className="reviews_carousel_image_wrapper">
                                    <img className="reviews_carousel_image" src={review03} alt="review_image_03"/>
                                </div>
                            </Carousel.Item>
                        </Carousel>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MainReviewsContent