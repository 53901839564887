import aboutContainerPicture from "../res/about_container_picture.png";

function MainAboutContent(payload) {
    return (
        <div className="basic_container_wrapper spec_container_height">
            <div className="basic_container" ref={payload.reference}>
                <div className="about_container_picture_left about_container_flex_up">
                    <img src={aboutContainerPicture} alt="about_container_picture"/>
                </div>
                <div className="basic_container_text about_container_text about_container_flex_down">
                    <div>
                        <h3>Почему выбирают именно нас?</h3>
                        <ul className="no_margin_bottom">
                            <li>Поддержка на всех этапах
                                <br/>
                                <p>
                                    Консультируем подробно и быстро
                                </p>
                            </li>
                            <li>Бесплатные инструкции
                                <br/>
                                <p>
                                    Расскажем, как пополнить счет, как получить перевод, как оплатить товары и
                                    услуги
                                </p>
                            </li>
                            <li>Представители в городах-миллионниках
                                <br/>
                                <p>
                                    Наши представители готовы с вами встретиться лично и оказать полную консультацию
                                </p>
                            </li>
                            <li>Бесплатная доставка
                                <br/>
                                <p>
                                    Доставим вашу карту бесплатно в регионы и по Москве
                                </p>
                            </li>
                            <li className="no_margin_bottom">Гарантия возврата денег
                                <br/>
                                <p className="no_margin_bottom">
                                    Вернем все в полном объеме, если не выполним обязательства
                                </p>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MainAboutContent