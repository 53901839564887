import {Route, Routes} from "react-router-dom";
import MainPage from "../ui/pages/MainPage";

//router xd
function Router() {
    return (
        <Routes>
            {/*commented for protected routes time*/}
            {/*<Route path="/" element={<ProtectedRoute/>}>*/}
            {/*    */}
            {/*</Route>*/}
            <Route path="/" element={<MainPage/>}/>
        </Routes>
    )
}

export default Router