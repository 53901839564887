import shoppingCartIcon from "../res/shopping_cart_pc.png";
import atmIcon from "../res/ic_atm.png";
import subIcon from "../res/subscription.png";
import worldwideIcon from "../res/worldwide.png";
import contactlessIcon from "../res/contactless.png";
import currencyPiggyBank from "../res/currency_piggy_bank.png"

function MainLandContent(payload) {
    return(
        <div className="basic_container_wrapper second_background full_advantages_container_height">
            <div className="full_height_container_text basic_container_vertical" ref={payload.reference}>
                <div>
                    <h3 className="header2">Какие преимущества можно получить с иностранными
                        картами Visa и MasterCard?</h3>
                </div>
                <div className="basic_container_centered advantages_container_height">
                    <div className="advantages_equal_images_container">
                        <div className={"first_item"}>
                            <img className="advantages_equal_images" src={shoppingCartIcon} alt="advantages_image_01"/>
                            <p>Совершайте покупки в зарубежных онлайн магазинах</p>
                        </div>
                        <div>
                            <img className="advantages_equal_images" src={atmIcon} alt="advantages_image_02"/>
                            <p>Расплачивайтесь в магазинах и снимайте наличные деньги в банкоматах за границей</p>
                        </div>
                        <div>
                            <img className="advantages_equal_images" src={subIcon} alt="advantages_image_03"/>
                            <p>Оплачивайте иностранные сервисы, подписки и приложения</p>
                        </div>
                        <div>
                            <img className="advantages_equal_images" src={worldwideIcon} alt="advantages_image_04"/>
                            <p>Получайте и переводите деньги из-за границы</p>
                        </div>
                        <div>
                            <img className="advantages_equal_images" src={contactlessIcon} alt="advantages_image_05"/>
                            <p>Карты зарубежных банков привязываются к Apple/Samsung/Google Pay/PayPal и другим
                                платежным системам</p>
                        </div>
                        <div>
                            <img className="advantages_equal_images" src={currencyPiggyBank} alt="advantages_image_06"/>
                            <p>Возможность хранить свои сбережения в валюте на банковском счету надежного банка</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default MainLandContent