import React from 'react';
import ReactDOM from 'react-dom/client';
import './ui/style/index.css';
import Router from './system/Router';
import {BrowserRouter} from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';

//using browser router
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<React.StrictMode>
    <BrowserRouter>
        <Router/>
    </BrowserRouter>
</React.StrictMode>);