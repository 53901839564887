function MainFooter() {
    return (
        <div className="footer">
            <div className="footer_text_wrapper">
                <p>
                    Developed by 84softworks
                </p>
                <p>
                    84softworks@gmail.com
                </p>
            </div>
        </div>
    )
}

export default MainFooter