function MainHowToContent(payload) {
    return (
        <div className="basic_container_wrapper spec_container_height can_be_portrait">
            <div className="how_to_container_text basic_container_vertical landscape_only" ref={payload.reference}>
                <div>
                    <h3 className="header2">Пять простых шагов к получению вашей карты</h3>
                </div>
                <div className="basic_container_centered">
                    <div className="how_to_equals_container how_to_equals_title">
                        <div>
                            <h3>Свяжитесь с нами</h3>
                        </div>
                        <div>
                            <h3>Пришлите необходимые документы</h3>
                        </div>
                        <div>
                            <h3>Ожидайте подтверждения об открытии счета</h3>
                        </div>
                        <div>
                            <h3>Оплатите комиссию</h3>
                        </div>
                        <div>
                            <h3>Готово!</h3>
                        </div>
                    </div>
                </div>
                <div className="basic_container_centered">
                    <div className="how_to_balloons_connector"/>
                    <div className="how_to_equals_container">
                        <div>
                            <div className="how_to_balloon"><p>1</p></div>
                            <p>Мы работаем почти 24/7, поэтому готовы вам ответить максимально оперативно.</p>
                        </div>
                        <div>
                            <div className="how_to_balloon"><p>2</p></div>
                            <p>При оформлении по доверенности вам нужно будет отправить нотариально заверенные документы
                                почтой нашему сотруднику. Если же оформление происходит без доверенности, то нам нужен
                                только скан паспорта РФ или загранпаспорта.</p>
                        </div>
                        <div>
                            <div className="how_to_balloon"><p>3</p></div>
                            <p>На этом шаге вам остается лишь ожидать, пока наш сотрудник получит пакет документов и
                                посетит с ними банк. В случае оформления без доверенности - ожидать одобрения от банка и
                                выпуск карты.</p>
                        </div>
                        <div>
                            <div className="how_to_balloon"><p>4</p></div>
                            <p>Оплатите комиссию сервиса, величина которой зависит от типа карты, которую вы
                                выбрали.</p>
                        </div>
                        <div>
                            <div className="how_to_balloon"><p>5</p></div>
                            <p>Вы можете использовать свою именную карту зарубежного банка для международных
                                транзакций.</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="portrait_only how_to_container_text basic_container_vertical">
                <div>
                    <h3 className="header2">Пять простых шагов к получению вашей карты</h3>
                </div>
                <div className="how_to_container_portrait">
                    <div className="how_to_container_portrait_balloons_zone">
                        <div className="how_to_balloons_portrait_connector"/>
                        <div className="how_to_container_portrait_columns">
                            <div className="how_to_container_portrait_balloons_wrapper">
                                <div className="how_to_balloon"><p>1</p></div>
                            </div>
                            <div className="how_to_container_portrait_balloons_wrapper">
                                <div className="how_to_balloon"><p>2</p></div>
                            </div>
                            <div className="how_to_container_portrait_balloons_wrapper">
                                <div className="how_to_balloon"><p>3</p></div>
                            </div>
                            <div className="how_to_container_portrait_balloons_wrapper">
                                <div className="how_to_balloon"><p>4</p></div>
                            </div>
                            <div className="how_to_container_portrait_balloons_wrapper">
                                <div className="how_to_balloon"><p>5</p></div>
                            </div>
                        </div>
                    </div>
                    <div className="how_to_container_portrait_text_zone">
                        <div className="how_to_container_portrait_columns">
                            <div className="how_to_container_portrait_text">
                                <h3>Свяжитесь с нами</h3>
                                <p>Мы работаем почти 24/7, поэтому готовы вам ответить максимально оперативно.</p>
                            </div>
                            <div className="how_to_container_portrait_text">
                                <h3>Пришлите необходимые документы</h3>
                                <p>При оформлении по доверенности вам нужно будет отправить нотариально заверенные
                                    документы почтой нашему сотруднику. Если же оформление происходит без доверенности,
                                    то нам нужен только скан паспорта РФ или загранпаспорта.</p>
                            </div>
                            <div className="how_to_container_portrait_text">
                                <h3>Ожидайте подтверждения об открытии счета</h3>
                                <p>На этом шаге вам остается лишь ожидать, пока наш сотрудник получит пакет документов и
                                    посетит с ними банк. В случае оформления без доверенности - ожидать одобрения от
                                    банка и выпуск карты.</p>
                            </div>
                            <div className="how_to_container_portrait_text">
                                <h3>Оплатите комиссию</h3>
                                <p>Оплатите комиссию сервиса, величина которой зависит от типа карты, которую вы
                                    выбрали.</p>
                            </div>
                            <div className="how_to_container_portrait_text">
                                <h3>Готово!</h3>
                                <p>Вы можете использовать свою именную карту зарубежного банка для международных
                                    транзакций.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MainHowToContent